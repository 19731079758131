import { axiosApiInstance } from "@/plugins/axios";

import { bus } from "@/main.js";
function add_payment_schedule(){
  bus.$emit("add-payment-schedule", {
    load: false,
    items: this.items,
    all:true,
  });
}
function create_payment_schedule() {
  bus.$emit("add-payment-schedule", {
    load: false,
    items: this.items,
  });
}
function view_user(item) {

  bus.$emit("farmer-view", {
    name: item.farmer_name,
    data: item,
    farmer_id: item.user_id ? item.user_id : item.farmer_id,
    tab: "payments",
  });
}


function get_produce_pending_payments() {
  this.loadingPayments = true;

  axiosApiInstance({
    url: this.url+"/payments/",
    method: "get",
  })
    .then((res) => {
      this.items = this.allpayments = res.data.data;
      // console.log(res.status);
      this.loadingPayments = false;
    })
    .catch((error) => {
      this.loadingPayments = false;
      console.log(error);
    });
  // axios.get
}

function get_stats() {
  this.loading = true;

  axiosApiInstance({
    url: this.url+"/payments/stats/",
    method: "get",
  })
    .then((res) => {
      let obj = res.data.data[0];
      this.unverified=obj.pending_verification.pending;
      this.verified_by = obj.pending_approval.verified_by;
      this.unapproved=obj.pending_approval.unapproved;
      this.recent_payments=obj.recent
      // console.log(res.status);
      this.loading = false;
    })
    .catch((error) => {
      console.log(error);
    });
  // axios.get
}

function request_top_up() {
  this.loadingBalance = true;

  axiosApiInstance({
    url: this.url+"/payments/emails/topup/",
    method: "post",
  })
    .then((response) => {
      this.loadingBalance=false;
      this.$vToastify.success(response.data.message);
    })
    .catch((error) => {
      this.loadingBalance=false;
      this.$vToastify.error("Request for top up failed, contact offtakersupport@ezyagric.com");
      console.log(error);

    });
  // axios.get
}


function get_user_balance(){
// Get user wallet balance
  if (this.can('view', 'payments')){
    this.loadingBalance=true;
    axiosApiInstance({
      url: this.url+"/payments/balance/",
      method: "get",
    }).then((res) => {
      this.loadingBalance=false;
      this.momo_balance = res.data.momo_balance;
      this.bank_balance = res.data.bank_balance;
      this.wallet_balance = res.data.balance;
    }).catch((error)=>{
      console.log(error);
      this.loadingBalance=false;
    })
  }
}

function format_num(num){
  return num?Number(num).toLocaleString():'0'
}

function filter_usertypes(){

  this.items=this.user_type?this.allpayments.filter(
    item=>item.user_type==this.user_type
  ):this.allpayments;
}

function is_agent(){
  return this.prefix!=="";
}

export { 
  get_produce_pending_payments, 
  get_stats, add_payment_schedule,
  format_num, get_user_balance,
  create_payment_schedule,view_user,
  filter_usertypes, request_top_up,
  is_agent
};
