<template>
  <div>
    <b-navbar>
      <b-navbar-brand class="page-title">
        <span>PAYMENTS</span>
        <!-- <b-icon-chevron-down></b-icon-chevron-down>
        <b-link class="text-dark" to="/payments/schedules">SCHEDULES</b-link> -->
      </b-navbar-brand>
      <b-navbar-nav class="ml-auto">
        <!--  v-b-modal.add-schedule -->
        <b-button
          v-if="can('create_schedule', 'payments')"
          :disabled="
            !can('create_schedule', 'payments') ||
              items.length == 0 ||
              total_amount(items) <= 0
          "
          @click="create_payment_schedule"
          v-analytics:click="['create_schedule', { element: 'Payments' }]"
          squared
          variant="primary"
        >
          <b-icon-plus></b-icon-plus> Create Payments Schedule
        </b-button>
      </b-navbar-nav>
    </b-navbar>
    <div class="page-controls">
      <b-row class="m-0 w-100">
        <div class="col">
          <div class="d-flex">
            <b-select
              class="form-control form-select mr-2"
              variant="light"
              v-model="criteria.supplier"
              :options="user_types"
              v-analytics:change="[
                'filter_pending_payments_by_supplier',
                { element: 'Payments' },
              ]"
              required
            >
            </b-select>
            <div>
              <b-form class="d-inline-block">
                <b-form-input
                  placeholder="Search Name"
                  type="search"
                  v-analytics:change="[
                    'search_supplier_payments',
                    { element: 'Payments' },
                  ]"
                  class="search"
                  v-model="criteria.searchfield"
                ></b-form-input>
              </b-form>
            </div>
            <div class="pl-2">
              <date-picker @dates-selected="cfilterByDate"></date-picker>
            </div>
          </div>
        </div>
        <div class="col-auto ">
          <div class="sidebar-width text-right">
            <button
              class="btn-opt primary"
              @click="reload"
              v-analytics:click="['refresh_payments', { element: 'Payments' }]"
            >
              <b-icon-arrow-counterclockwise></b-icon-arrow-counterclockwise>
              Refresh
            </button>
            <button
              class="btn-opt primary"
              v-print="'#PaymentsTable'"
              v-analytics:click="[
                'print_payments_page',
                { element: 'Payments' },
              ]"
            >
              <b-icon-printer></b-icon-printer> Print
            </button>
            <!-- <button class="btn-opt primary" ><b-icon-book></b-icon-book> Export </button> -->
            <download-excel
              class="btn btn-opt  primary"
              :data="items"
              :fields="csvFields"
              v-analytics:click="[
                'export_payments_csv',
                { element: 'Payments' },
              ]"
              type="csv"
            >
              <b-icon-book></b-icon-book> Export
            </download-excel>
          </div>
        </div>
      </b-row>
    </div>
    <b-row class="mx-0 ">
      <b-col cols class="col pt-2">
        <b-container v-show="!loadingPayments && items.length == 0">
          <b-row class="d-flex justify-content-center mb-3">
            No Pending Payments&nbsp;
            <!-- <b-spinner
              label="Large Spinner"
            ></b-spinner> -->
          </b-row>
        </b-container>
        <b-container v-show="loadingPayments">
          <b-row class="d-flex justify-content-center mb-3">
            <b-spinner
              class="loader"
              style="width: 3rem; height: 3rem;"
              label="Large Spinner"
            ></b-spinner>
          </b-row>
        </b-container>

        <b-table
          id="PaymentsTable"
          v-show="items.length > 0 && !loadingPayments"
          sticky-header="75vh"
          :filter="criteria"
          :fields="fields"
          :items="items"
          @row-clicked="view_user"
          :filter-function="filterItems"
          hover
        >
          <template #cell(farmer_name)="data">
            <!-- <b-avatar icon="person">{{ data.item.farmer_name[0] }}</b-avatar> -->
            {{ data.item.farmer_name }}
          </template>

          <!-- <template #cell(contact)="data">
            {{ data.item.contact }}
          </template> -->
          <template #cell(amount)="data">
            {{ format_num(data.item.payment) }}
          </template>
          <template #cell(pending_balance)="data">
            {{ format_num(data.item.pending_balance) }}
          </template>
          <template #cell(usertype)="data">
            <usertype-pill :user_type="data.item.user_type"> </usertype-pill>
          </template>

          <template #cell(produce)="data">
            {{
              format_num(
                data.item.pending_balance +
                  (data.item.advance ? data.item.advance : 0)
              )
            }}
          </template>
          <template #cell(net_balance)="data">
            {{ format_num(data.item.pending_balance - data.item.payment) }}
          </template>

          <template #cell(payment)="data">
            {{ format_num(data.item.payment) }}
          </template>
          <template #cell(advance)="data">
            {{ format_num(data.item.advance ? data.item.advance : 0) }}
          </template>

          <template #cell(time)="data">
            {{ time_format(data.item.time) }}
          </template>
        </b-table>
      </b-col>

      <b-col class="border-left col-auto pt-2">
        <div class="sidebar">
          <div class="pl-1">
            <h5 class="subtitle">Total Payment</h5>
            <h2 class="price-lg">UGX {{ total_amount(items) }}</h2>

            <b-button
              v-if="can('create_schedule', 'payments')"
              :disabled="!can('approve', 'payments') || items.length == 0"
              @click="add_payment_schedule"
              v-analytics:click="['pay_all', { element: 'Payments' }]"
              squared
              variant="success"
              class="btn-sm"
            >
              Make Payment to All</b-button
            >
          </div>
          <b-row
            v-if="loadingBalance"
            class="pl-1 pt-3 justify-content-center mb-3"
          >
            <b-spinner label="balance spinner"></b-spinner>
          </b-row>
          <div
            v-if="can('view', 'payments') && !loadingBalance && is_agent()"
            class="pl-1"
          >
            <h5 class="subtitle mb-0 mt-3">Wallet Balance</h5>
            <h3 class="text-success">UGX {{ format_num(wallet_balance) }}</h3>
          </div>
          <div
            v-if="can('view', 'payments') && !loadingBalance && !is_agent()"
            class="pl-1"
          >
            <h5 class="subtitle mb-0 mt-3">Momo Wallet Balance</h5>
            <h3 class="text-success">UGX {{ format_num(momo_balance) }}</h3>
          </div>
          <div
            v-if="can('view', 'payments') && !loadingBalance && !is_agent()"
            class="pl-1"
          >
            <h5 class="subtitle mb-0 mt-3">Bank Wallet Balance</h5>
            <h3 class="text-success">UGX {{ format_num(bank_balance) }}</h3>
            <b-button
              :disabled="
                !can('create_schedule', 'payments') ||
                  !can('approve', 'payments') ||
                  !can('verify', 'payments')
              "
              squared
              @click="request_top_up"
              v-analytics:click="['top_up', { element: 'Payments' }]"
              variant="outline-success"
              class="btn-sm"
              >Request Top Up</b-button
            >
          </div>
          <div class="height50">
            <div v-show="!loading" class="pl-1 mt-3">
              <h5 class="subtitle">Notifications</h5>
              <b-link
                v-show="unverified"
                class="no-decorate"
                v-analytics:click="[
                  'open_verifications',
                  { element: 'Payments' },
                ]"
                to="/payments/schedules"
              >
                <div class="d-flex align-items-center not-item">
                  <b-icon-exclamation-circle class="h2 mb-2" variant="danger">
                  </b-icon-exclamation-circle>
                  <div class="pl-3">
                    <h6 class=" m-0">{{ unverified }} Verification Requests</h6>
                    <span class="text-muted"
                      >{{ unverified }} Payment schedules need
                      Verification</span
                    >
                  </div>
                  <b-icon-chevron-right
                    class="ml-auto text-muted"
                  ></b-icon-chevron-right>
                </div>
              </b-link>
              <b-link
                class="no-decorate"
                to="/payments/schedules"
                v-analytics:click="['open_approvals', { element: 'Payments' }]"
              >
                <div class="d-flex align-items-center not-item">
                  <b-icon-exclamation-circle-fill
                    class="h2 mb-2"
                    variant="warning"
                  >
                  </b-icon-exclamation-circle-fill>
                  <div class="pl-3">
                    <h6 class=" m-0">{{ unapproved }} Approval Requests</h6>
                    <span class="text-muted"
                      >{{ unapproved }} Payment schedules need Approval</span
                    >
                    <span class="text-muted"
                      >. Verified By {{ verified_by }}</span
                    >
                  </div>
                  <b-icon-chevron-right
                    class="ml-auto text-muted"
                  ></b-icon-chevron-right>
                </div>
              </b-link>
            </div>
            <div class=" mt-3 px-1">
              <h5 class="subtitle">Payment History</h5>
              <b-link
                v-for="(schedule, idx) in recent_payments"
                :key="'d' + idx"
                :to="{ name: 'schedule', params: { schedule_id: schedule.id } }"
              >
                <div class="d-flex align-items-center history-item mt-1  py-2">
                  <b-icon-person-circle
                    class="h2 mb-2"
                    v-show="schedule.farmers == 1"
                    variant="success"
                  >
                  </b-icon-person-circle>
                  <b-icon-calendar3
                    class="h2 mb-2"
                    variant="success"
                    v-show="schedule.farmers > 1"
                  >
                  </b-icon-calendar3>
                  <div class="pl-3">
                    <h6 class="text-dark m-0">
                      {{
                        schedule.name
                          ? schedule.name
                          : time_format(schedule.authorized_at)
                      }}
                    </h6>
                    <span class="text-muted"
                      >{{ time_diff(schedule.authorized_at) }} - UGX
                      {{ schedule.amount_paid.toLocaleString() }}</span
                    >
                  </div>
                </div>
              </b-link>

              <!-- <hr class="p-0 mt-1 mb-3" style="background-color: #e2e2e2" /> -->
              <b-link to="/payments/schedules" class="pt-3 d-block text-primary"
                >View Full History <b-icon-arrow-right></b-icon-arrow-right
              ></b-link>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

    <add-payment-schedule @SuccessfulPayment="reload()"></add-payment-schedule>
    <farmer-modal @SuccessfulPayment="reload()"></farmer-modal>
  </div>
</template>

<script>
import moment from "moment";
import {
  get_produce_pending_payments,
  get_user_balance,
  get_stats,
  add_payment_schedule,
  request_top_up,
  view_user,
  create_payment_schedule,
  format_num,
  is_agent,
  filter_usertypes,
} from "./actions.js";

import { can } from "@/modules/auth/utils.js";
import FarmerModal from "@/components/farmer_modal/Farmer.vue";
import AddPaymentSchedule from "@/components/add_payment_schedule/AddPaymentSchedule.vue";
import UsertypePill from "../../components/usertype_pill/UsertypePill.vue";
import DatePicker from "@/modules/produce/components/ProduceDatePicker.vue";
import dateMixin from "@/modules/produce/date_mixin";

export default {
  components: {
    AddPaymentSchedule,
    FarmerModal,
    UsertypePill,
    DatePicker,
  },
  mixins: [dateMixin],
  computed: {
    url() {
      return (
        process.env.VUE_APP_BACKEND +
        "/api/v3/" +
        this.$store.state.project_id +
        this.$store.state.url_prefix
      );
    },
    prefix() {
      return this.$store.state.url_prefix;
    },
  },
  data() {
    let fields = [
      { key: "farmer_name", label: "Name" },
      { key: "usertype", label: "" },
      { key: "produce", label: "Produce(UGX)" },
      { key: "advance", label: "Advance" },
      { key: "amount", label: "Net Payment" },
      { key: "net_balance", label: "Balance" },
      { key: "time", label: "Date" },
    ];
    let value_chains = ["Coffee", "Soybeans", "Maize"];
    let searchfield = "";
    let suppliers = [
      "Last Season",
      "Last Seasons",
      "Last 2 Seasons",
      "All Seasons",
      "Single Date",
      "Date Range",
    ];

    return {
      fields,
      items: [],
      suppliers,
      searchfield,
      value_chains,
      loading: false,
      approved: 0,
      unapproved: 0,
      stats: [],
      approved_by: "",
      user_type: "",
      user_types: [
        { value: null, text: "All Suppliers" },
        { value: "agent", text: "Agents" },
        { value: "farmer", text: "Farmers" },
      ],
      recent_payments: [],
      selected_supplier: "",
      loadingBalance: false,
      loadingPayments: false,
      loadingNotifications: false,
      balance: 0,
      wallet_balance: 0,
      momo_balance: 0,
      bank_balance: 0,
      unverified: 0,
      verified: 0,
      verified_by: "",
      allpayments: [],
      csvFields: {
        Name: "farmer_name",
        Contact: "contact",
        "Pending Balance(Produce - Advance)": "pending_balance",
        Advance: "advance",
        "Amount To be paid": "amount",
        Date: {
          callback: (time) => {
            return moment(time, "YYYY-MM-DD HH:mm:ss").format("MMMM Do YYYY");
          },
        },
      },
      criteria: {
        supplier: null,
        searchfield: null,
        selectedPeriod: [""],
      },
    };
  },
  methods: {
    can,
    add_payment_schedule,
    get_produce_pending_payments,
    get_user_balance,
    get_stats,
    filter_usertypes,
    request_top_up,
    format_num,
    is_agent,
    reload() {
      this.get_produce_pending_payments();
      this.get_stats();
      this.get_user_balance();
    },
    create_payment_schedule,
    total_amount(list) {
      return list
        .reduce((a, b) => a + (Number(b["payment"]) || 0), 0)
        .toLocaleString();
    },
    time_format(time) {
      return moment(time, "YYYY-MM-DD HH:mm:ss").format("lll");
    },
    time_diff(time) {
      return moment(time).fromNow();
    },
    view_user,
    filterItems(row, criteria) {
      let filters = [];
      if (criteria.supplier) {
        filters.push(row.user_type == criteria.supplier);
      }
      if (criteria["selectedPeriod"].length==2) {
        filters.push(
          moment(row["time"]).isBetween(...this.criteria.selectedPeriod)
        );
      }
      if (criteria.searchfield) {
        filters.push(
          row["farmer_name"]
            .toLowerCase()
            .includes(criteria.searchfield.toLowerCase())
        );
      }
      return !filters.includes(false);
    },
  },
  mounted() {
    this.dateInit();
    this.reload();
    this.$analytics.track("open_payments", { element: "Payments" });
  },
};
</script>

<style scoped lang="scss">
.no-decorate {
  text-decoration: none !important;
}

.not-item {
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 16px;
  // margin-bottom: 16px;
  margin-top: 16px;
  h6 {
    font-size: 14px !important;
    color: #000;
    font-weight: 600;
  }
  span {
    font-size: 14px !important;
    line-height: 100% !important;
  }
}

.history-item {
  h6 {
    font-size: 12pt;
  }
}
.side-bar-position {
  height: 80vh;
  overflow-y: auto;
}
.height50 {
  height: 50vh;
  overflow-y: auto;
}
</style>
